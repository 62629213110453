import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

// The padding and border length are defined here because we need these values
// to position text highlights appropriately on <HighlightedTextArea />
export const textareaPadding = '1em';
export const textareaBorderLength = '1px';

export const Textarea = styled.textarea<{ $hasError?: boolean }>`
  margin: 0;
  padding: ${textareaPadding};
  font: inherit;
  width: 100%;
  color: ${colors.darkContentGrey};
  background-color: ${colors.white};
  overflow: auto;
  border: ${textareaBorderLength} solid ${colors.elementGrey};
  border-radius: 4px;
  transition: all ${transition};
  vertical-align: top;
  ${(props) =>
    props.$hasError &&
    `
    border-color: ${colors.alertRed};
    box-shadow: 0 0 0 1px ${colors.alertRed};
  `}

  &:focus {
    border-color: ${colors.brandBlue};
    box-shadow: 0 0 0 1px ${colors.brandBlue};
    outline: 0;
  }

  ::placeholder {
    color: ${colors.elementGrey};
  }
`;
